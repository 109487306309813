

@font-face {
  font-family: 'Sora';
  src: url('../assets/fonts/Sora-VariableFont_wght.woff2') format('woff2'),
  url('../assets/fonts/Sora-VariableFont_wght.woff') format('woff'),
  url('../assets/fonts/Sora-VariableFont_wght.ttf') format('truetype');
}

body {
  font-family: 'Sora';
}

@mixin h1 {
  font-weight: bold;
  line-height: 28px;
  font-size: 30px;
  //text-transform: uppercase;
}

@mixin h2 {
  font-weight: 500;
  font-size: 24px;
}

@mixin h3 {
  font-weight: bold;
  font-size: 14px;
}

@mixin h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

@mixin h5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
}

@mixin h6 {
  font-weight: 400;
  font-size: 11px;
}

@mixin h7 {
  font-weight: 400;
  font-weight: 500;
}

@mixin p {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.6px;
  line-height: 20px;
}

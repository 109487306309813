@import "src/styles/colors";

.root {
  width: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;

  &__left {
    display: flex;
  }

  &__rigth {

  }

  &__item {
    text-decoration: none;
    margin-right: 10px;
    color: $primaryGray;

    &:hover {
      text-decoration: underline;
    }
  }
}
$applePink: #FF2F56;
$layoutBreakPointMiddle: 720px;

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo {
  filter: drop-shadow(0px 3.11789px 3.11789px rgba(0, 0, 0, 0.25));
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  width: 100%;

  @media (min-width: $layoutBreakPointMiddle) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}

.logIn {
  background: $applePink;
  color: white;

  @media (min-width: $layoutBreakPointMiddle) {
    order: 1;
  }
}

.notNow {
  border: 1px solid $applePink;
  color: $applePink;
}

.title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.description {
  text-align: center;
  max-width: 70%;
}

.authButton {
  cursor: pointer;
  border-radius: 10px;
  width: 345px;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  
  @media (max-width: 375px) {
    width: 90%;
  }
  
}
$applePink: #FF2F56;
$layoutBreakPointMiddle: 720px;

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.logo {
  filter: drop-shadow(0px 3.11789px 3.11789px rgba(0, 0, 0, 0.25));
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  width: 100%;

  @media (min-width: $layoutBreakPointMiddle) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}

.logIn {
  background: $applePink;
  color: white;

  @media (min-width: $layoutBreakPointMiddle) {
    order: 1;
  }
}

.notNow {
  border: 1px solid $applePink;
  color: $applePink;
}

.title {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  background: -webkit-linear-gradient(180deg, #2B3FF1, #61FF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  text-align: center;
  max-width: 70%;
}
